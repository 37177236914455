import React, { useState, useMemo } from "react";
import "./billing-plan.scss";
import Typography from "@mui/material/Typography";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import {
  useBillingPlanList,
  useAssignBillingPlan,
  useLagoPortal,
  useLagoCheckout,
  usePaymentIntent,
} from "../../../hooks/billing.hooks";
import { useMyInfo } from "../../../hooks/user.hooks";
import InvoiceHistoryButton from "./invoice-history-btn";
import CheckoutButton from "./checkout-btn";
import { DollarIcon, Checkbox } from "../../../svgs";
import CheckoutForm from "./checkout-form";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(
  "pk_test_51QpQLL4FEPlXtswBiePD9vAOSzcf2vQIHnz7HtPdJcVGwyCSujvZXl5z42zkesroCtNGE3UeRGJn83RcHQ8LjzNg00AqKYLRh4"
);

const BillingSetting = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const assignBillingPlan = useAssignBillingPlan();
  const { data: paymentIntentSecret } = usePaymentIntent();
  const { data: userInfo } = useMyInfo();

  const formatPrice = (cents) => {
    return new Intl.NumberFormat("en-US").format(cents / 100);
  };

  const getIntervalLabel = (interval) => {
    const labels = {
      weekly: "Week",
      monthly: "Month",
      yearly: "Year",
    };
    return labels[interval] || "";
  };

  const usePlans = () => {
    const { data: result = [], isLoading, error } = useBillingPlanList();

    const transformedPlans = useMemo(
      () =>
        result.plans?.map((plan) => ({
          id: plan.lago_id,
          name: plan.name,
          code: plan.code,
          external_id: plan.lago_id,
          price: formatPrice(plan.amount_cents, plan.amount_currency),
          interval: getIntervalLabel(plan.interval),
          features: plan.description.split(",").map((description) => ({
            description,
          })),
          taxes: plan.taxes,
          sub_price: formatPrice(
            plan.charges[0]?.properties?.amount * 100 || 0
          ),
        })) || [],
      [result]
    );

    return {
      plans: { transformedPlans, name: result },
      isLoading,
      error,
    };
  };

  const { plans } = usePlans();

  const onSubmit = async (selectedId) => {
    setSelectedPlan(selectedId);
    const plan = plans.transformedPlans.find((p) => p.id === selectedId);
    if (!plan) return;

    assignBillingPlan.mutate({
      external_id: selectedId,
      external_customer_id: userInfo.companyId,
      plan_code: plan.code,
      name: plan.name,
    });
  };

  const lagoPortal = useLagoPortal();

  const handleInvoiceHistoryClick = () => {
    lagoPortal.mutate(userInfo.companyId);
  };

  const checkoutPortal = useLagoCheckout();

  const handleCheckout = () => {
    checkoutPortal.mutate(userInfo.companyId);
  };

  const PlanCard = ({ plan, isSelected }) => (
    <div className={`pricing-card ${isSelected ? "selected" : ""}`}>
      <div className="title">{plan.name}</div>
      <div className="price">
        <DollarIcon />
        {plan.price} <span>Per Company Per {plan.interval}</span>
      </div>
      <div className="sub-price">${plan.sub_price} Per Additional Broker</div>
      <button
        className="upgrade-button"
        type="submit"
        disabled={isSelected}
        onClick={() => onSubmit(plan.id)}
      >
        {isSelected ? "Selected" : "Upgrade"}
      </button>
      <div className="features">
        <div className="feature-title">{plan.features[0].description}</div>
        <ul>
          {plan.features.slice(1).map(
            (feature) =>
              feature.description !== "" && (
                <li key={feature}>
                  <Checkbox />
                  {feature.description}
                </li>
              )
          )}
        </ul>
      </div>
    </div>
  );

  return (
    <div className="billing-plans">
      <InvoiceHistoryButton onClick={handleInvoiceHistoryClick} />
      <CheckoutButton onClick={handleCheckout} />
      <div>
        {paymentIntentSecret && (
          <Elements
            options={{
              clientSecret: paymentIntentSecret,
              appearance: {
                theme: "stripe",
              },
              loader: "auto",
            }}
            stripe={stripePromise}
          >
            <CheckoutForm />
          </Elements>
        )}
      </div>
      {plans.transformedPlans.length > 0 && (
        <div>
          <Typography
            className="billing-header"
            style={{ fontSize: 30, fontWeight: 700 }}
          >
            Spend peanuts, <span>make bank.</span>
          </Typography>
          <Typography className="billing-header">
            Never you been able to cut cost and grow with one system. We want
            you.
          </Typography>
        </div>
      )}
      <div className="pricing-container">
        {plans.transformedPlans.length > 0 ? (
          plans.transformedPlans.map((plan) => (
            <PlanCard
              key={plan.id}
              plan={plan}
              isSelected={selectedPlan === plan.id}
              onSelect={() => onSubmit(plan.id)}
            />
          ))
        ) : (
          <Typography align="center" sx={{ mb: 4 }}>
            Already subscibed to {plans.name}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default BillingSetting;
