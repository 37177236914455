import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { makeStyles } from "@mui/styles";
import { Router } from "@reach/router";
import { navigate } from "gatsby";
import { useMemo, useState } from "react";
import { STAFF_ROLE, USER_ROLE } from "../../commons/enum";
import HeaderComponent from "../../components/HeaderComponent";
import CompanySetting from "../../features/account/company-setting";
import PersonalAccount from "../../features/account/personal-account";
import { useStaffInfo } from "../../hooks/staff.hooks";
import { useMyInfo } from "../../hooks/user.hooks";
import { useTabStyles } from "../../utils/styles-util";
import { useCompanyInfo } from "../../hooks/company.hooks";
import BillingSetting from "../../features/account/billing-plan";

const useStyles = makeStyles({
  black: {
    color: "#000000",
  },
  unselect: {
    color: "rgba(0, 0, 0, 0.54)",
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AccountSetting({ location }) {
  const classes = useStyles();
  const tabsStyles = useTabStyles();
  const { data: companyInfo } = useCompanyInfo();
  const { pathname } = location;
  const { data: userInfo } = useMyInfo();
  const [value, setValue] = useState(() => {
    if (pathname.includes("company-setting")) return 1;
    if (pathname.includes("billing-plan")) return 2;
    return 0;
  });
  const { data: staffInfo } = useStaffInfo();
  const isCompanyAdminRole = useMemo(() => {
    return staffInfo?.staffRoles.some(
      (role) => role.role === STAFF_ROLE.COMPANY_ADMIN
    );
  }, [staffInfo]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    newValue === 2 && navigate("/account/billing-plan");
    newValue === 1 && navigate("/account/company-setting");
    newValue === 0 && navigate("/account/");
  };

  return (
    <div className="sharing-referral-page account-page">
      <HeaderComponent
        headerContent={
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="secondary"
            textColor="inherit"
            classes={{
              root: tabsStyles.tabs,
              flexContainer: tabsStyles.tabsFlexContainer,
              indicator: tabsStyles.indicator,
            }}
          >
            <Tab
              label="Personal Account"
              {...a11yProps(0)}
              classes={{
                textColorInherit:
                  value === 0 ? classes.black : classes.unselect,
              }}
            />
            {!companyInfo.message &&
              (isCompanyAdminRole ||
                userInfo?.userRole === USER_ROLE.ADMIN) && (
                <Tab
                  label="Company Setting"
                  {...a11yProps(1)}
                  classes={{
                    textColorInherit:
                      value === 1 ? classes.black : classes.unselect,
                  }}
                />
              )}
            {!companyInfo.message &&
              (isCompanyAdminRole ||
                userInfo?.userRole === USER_ROLE.ADMIN) && (
                <Tab
                  label="Subscription and Payment"
                  {...a11yProps(2)}
                  classes={{
                    textColorInherit:
                      value === 2 ? classes.black : classes.unselect,
                  }}
                />
              )}
          </Tabs>
        }
      />
      <div className="account-page__main-container">
        <Router basepath="/account">
          <PersonalAccount path="/*" />
          <CompanySetting path="/company-setting" />
          <BillingSetting path="/billing-plan" />
        </Router>
      </div>
    </div>
  );
}
