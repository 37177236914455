import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import ReceiptIcon from "@mui/icons-material/Receipt";

const StyledButton = styled(Button)({
  textTransform: "none",
});

const InvoiceHistoryButton = ({ onClick = () => {} }) => {
  return (
    <div className="contact__import-btn">
      <StyledButton
        variant="text"
        component="label"
        role={undefined}
        tabIndex={-1}
        startIcon={<ReceiptIcon color="success" />}
        onClick={onClick}
      >
        Invoice History
      </StyledButton>
    </div>
  );
};

InvoiceHistoryButton.propTypes = {
  onClick: PropTypes.func,
};

export default InvoiceHistoryButton;
